// components/company.jsx
import React, { useState, useEffect } from 'react';
import CountryFlag from 'react-country-flag';
import companyLogo from '../images/bestecoleads_logo.png';
import BGImage from '../images/grayscale-bgimage.png';
import { FaLinkedin, FaTelegramPlane, FaEnvelope, FaPhone, FaWhatsapp } from 'react-icons/fa';
import './styles.css';

const CompanyInfo = {
    companyName: 'BestEcoLeads',
    responseTime: '10 minutes',
    homeCity: 'London',
    homeCountry: 'GB',
    targetUrl: 'https://bestecoleads.com',
    whatsappUrl: 'https://wa.me/message/PLL7AVRNBLAYL1',
    companyLogo: './images/bestecoleads_logo.png',
    productName: 'Non-EPC Domestic Property Lists',
    productDescription: 'Customized property lists specifically tailored for those lacking an EPC certificate, designed to empower your ECO4 outreach efforts. Ideal for canvassers and direct mailing campaigns, these lists offer unparalleled accuracy, ensuring your targets are precisely aligned with your campaign objectives. Covering any region within the UK, our service provides the flexibility to meet your specific requirements. Each list is meticulously compiled, guaranteeing the highest level of precision and is competitively priced per 1000 properties, ensuring optimal value for your investment.',
    percentOff: '35% OFF',
    saleEndingDays: 7
};

const socialLinks = [
    { name: 'LinkedIn', url: 'https://www.linkedin.com/company/bestecoleads', icon: <FaLinkedin /> },
    { name: 'Telegram', url: 'https://t.me/bestecoleads', icon: <FaTelegramPlane /> },
    { name: 'Email', url: 'mailto:innovation@coupledlogic.com', icon: <FaEnvelope /> },
    { name: 'Phone', url: 'tel:+442035763320', icon: <FaPhone /> }
];

const Company = () => {
    const city = CompanyInfo.homeCity;
    const countryCode = CompanyInfo.homeCountry;
    const [countdown, setCountdown] = useState('calculating...');

    // ... (Keep the geolocation effect same)

    useEffect(() => {
        const calculateCountdown = () => {
            const now = new Date();
            const saleEnd = new Date();
            saleEnd.setDate(now.getDate() + CompanyInfo.saleEndingDays);
            saleEnd.setHours(18, 0, 0, 0); // 6 PM

            const diff = saleEnd - now;
            const days = Math.floor(diff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((diff / 1000 / 60) % 60);
            const seconds = Math.floor((diff / 1000) % 60);

            return `${days}d ${hours}h ${minutes}m ${seconds}s`;
        };

        const interval = setInterval(() => {
            setCountdown(calculateCountdown());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    // ... (formatDate and tomorrowDate functions are not needed anymore)

    return (
        <div className="flex items-center justify-center min-h-screen" style={{ backgroundImage: `url(${BGImage})`, backgroundSize: '100%' }}>
            <div className="company-tile">
                <img
                    className="mx-auto h-32 w-32 rounded-full"
                    src={companyLogo}
                    alt={CompanyInfo.companyName}
                />
                <h3 className="mt-6 text-lg font-medium text-gray-900">{CompanyInfo.companyName}</h3>
                <div className="text-sm text-gray-500">
                    Located in {city} {countryCode && <CountryFlag countryCode={countryCode} svg />}
                </div>
                <div className="mt-3 inline-flex items-center justify-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                    Available Now
                </div>
                <div className="mt-3 text-xs text-gray-500">Avg response time: {CompanyInfo.responseTime}</div>
                <h4 className="mt-3 text-sm font-semibold text-gray-900">{CompanyInfo.productName}</h4>
                <p className="mt-3 text-sm text-gray-500 text-center">{CompanyInfo.productDescription}</p>
                <a href={CompanyInfo.whatsappUrl} className="whatsapp-cta">
                    <FaWhatsapp className="h-5 w-5" aria-hidden="true" />
                    Send us a message
                </a>
                <div className="sale-offer mt-3 text-xs font-bold text-gray-500">
                    {CompanyInfo.percentOff} sale ends in {countdown}
                </div>
                <div className="flex space-x-6 justify-center mb-4">
                    {socialLinks.map((link) => (
                        <a key={link.name} href={link.url} className="social-icon">
                            <span className="sr-only">{link.name}</span>
                            {link.icon}
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
                    }

export default Company;
